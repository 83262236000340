import React from 'react';

import { Box, Text, Heading, Center, Link, Button } from '@chakra-ui/react';
import { FaInstagram } from 'react-icons/fa';

import WithAnimation from '@/components/Common/WithAnimation';
import Image from '@/components/Common/LazyImage';
import useLang from '@hooks/useLang';

import { IG_FILTER_URL } from '@/constants';
import { BUTTON_PADDING_PROPS, BUTTON_PROPS, NAVIGATION_COLOR } from '@/constants/colors';
import { BG_INSTAGRAM_FILTER, BG_WEDDING_INFO } from '@/constants/assets';

import txt from './locales';

function InstagramFilter() {
  const lang = useLang();

  return (
    <Box bgColor="bgSecondary" padding="32px">
      <Box
        bgImage={`url(${BG_WEDDING_INFO})`}
        bgSize="cover"
        textAlign="center"
        color="mainColorText"
        width="100%"
        position="relative"
        borderRadius="16px"
        border="1px solid"
        borderColor={NAVIGATION_COLOR}
        boxShadow="xl"
      >
        <Box bgColor="rgb(228 226 206 / 18%)" padding="32px">
          <Box>
            <WithAnimation>
              <Heading
                textAlign="center"
                size="xl"
                fontWeight="normal"
                marginBottom="16px"
                color="mainColorText"
                fontFamily="CustomFont"
              >
                Engagement Filter
              </Heading>
              <Text fontSize="md" color="mainColorText">
                {txt.subTitle[lang]}
              </Text>
            </WithAnimation>
          </Box>
          {/* Section Photo */}
          <Center>
            <Image
              marginTop="32px"
              src={BG_INSTAGRAM_FILTER}
              maxWidth="85%"
              borderRadius="16px"
              boxShadow="md"
            />
          </Center>
          {/* Thanks Section */}
          <Box marginTop="32px">
            <WithAnimation>
              <Center>
                <Link
                  href={IG_FILTER_URL}
                  fontFamily="body"
                  color="white"
                  target="_blank"
                  fontWeight="normal"
                >
                  <Button
                    leftIcon={<FaInstagram />}
                    letterSpacing="1px"
                    textTransform="uppercase"
                    {...BUTTON_PROPS}
                    {...BUTTON_PADDING_PROPS}
                    size="sm"
                  >
                    {txt.textButton[lang]}
                  </Button>
                </Link>
              </Center>
            </WithAnimation>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(InstagramFilter);
